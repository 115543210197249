.footer {
  position: relative;
  width: 100%;
  overflow: visible;
  display: flex;
  flex-direction: column;
}

.backgroundWave {
  position: relative;
  width: 100%;
  height: auto;
  background-color: white;
  display: block;
  line-height: 0;
  font-size: 0;
}

.backgroundWave img {
  width: 100%;
  height: auto;
  object-fit: contain;
  display: block;
  vertical-align: bottom;
}

.footerWrapper {
  width: 100%;
  background-color: #e3e6ed;
  margin-top: -2px;
  position: relative;
}

.footerContent {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.logo {
  height: 3.5em;
  width: auto;
}

.logoText {
  margin-top: 0;
  color: #666;
  line-height: 1.6;
}

.column h3 {
  height: 3.5em;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: flex-end;
}

.column p {
  color: #666;
  line-height: 1.6;
}

.linkList {
  list-style: none;
  padding: 0;
  margin: 0;
}

.linkList li {
  margin-bottom: 0.8rem;
}

.linkList a {
  color: #666;
  text-decoration: none;
  transition: color 0.2s ease;
}

.linkList a:hover {
  color: #333;
}

.socialIcons {
  display: flex;
  gap: 1rem;
}

.socialIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease;
}

.socialIcon:hover {
  transform: scale(1.1);
}

.socialIcon img {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}

.socialIcon svg {
  width: 20px;
  height: 20px;
  color: black;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
  .footerContent {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .footerContent {
    grid-template-columns: 1fr;
    text-align: center;
    padding: 2rem 1rem;
  }

  .column {
    align-items: center;
  }

  .linkList {
    text-align: center;
  }

  .socialIcons {
    justify-content: center;
  }

  .column h3 {
    justify-content: center;
    height: auto;
    margin-bottom: 1rem;
  }

  .logoText {
    margin-top: 1rem;
  }
}

@media (max-width: 768px) {
  .mobileSpacing {
    margin-top: 2rem;
  }
}

.emailLink {
  font-weight: normal;
  text-decoration: underline;
  color: black;
  padding: 6px 0;
}
