.pageWrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.errorContainer {
  flex: 1;
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
}

.backgroundVideo {
  position: relative;
  width: 42%;
  height: 75%;
  object-fit: contain;
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.messageBox {
  max-width: 700px;
  text-align: left;
  background-color: #f5f5f5;
  padding: 1.8rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.messageBox h1 {
  margin-bottom: 2rem;
  font-size: clamp(1.3rem, 3vw, 2.4rem);
  text-align: left;
}

.messageBox p {
  color: #666;
  margin-bottom: 1.5rem;
  line-height: 1.8;
  font-size: clamp(0.8rem, 1.6vw, 1.2rem);
}

.messageBox p:last-child {
  margin-bottom: 0;
}

/* Tablet styles */
@media screen and (max-width: 1024px) {
  .messageBox h1 {
    font-size: clamp(1.3rem, 2.8vw, 2.2rem);
  }

  .messageBox p {
    font-size: clamp(0.8rem, 1.4vw, 1.1rem);
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .pageWrapper {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .errorContainer {
    flex-direction: column;
    padding: 0;
    gap: 0;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
  }

  .backgroundVideo {
    width: 100%;
    height: 35%;
    margin-top: 0;
  }

  .content {
    width: 100%;
    margin-top: 0;
    padding: 0;
  }

  .messageBox {
    width: 90%;
    padding: 1.5rem;
    margin: 0 auto;
    background-color: #f5f5f5;
  }

  .messageBox h1 {
    margin-bottom: 0.5rem;
    padding: 0;
  }

  .messageBox p {
    font-size: clamp(1.1rem, 2.2vw, 1.3rem);
    margin-bottom: 0.5rem;
    line-height: 1.4;
    padding: 0;
  }
}

/* Small mobile styles */
@media screen and (max-width: 480px) {
  .messageBox h1 {
    font-size: clamp(1.6rem, 3vw, 1.8rem);
  }

  .messageBox p {
    font-size: clamp(1rem, 1.8vw, 1.1rem);
  }
}

/* Add these new styles */
.messageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  width: 100%;
}

.backButton {
  margin-left: 2rem;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.backButton:hover {
  background-color: #444;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .backButton {
    width: 90%;
    padding: 1rem;
    font-size: 1.1rem;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .messageWrapper {
    align-items: center;
  }
}
